import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${atMinWidth.lg`
    flex-flow: row nowrap;
    gap: 24px;
    justify-content: space-between;
  `};
`;

export const HexWrapper = styled.div`
  position: absolute;
  left: -55px;
  top: 12%;
  width: 350px;

  svg {
    width: 100%;
    height: fit-content;
  }
`;

export const HexWrapperTwo = styled(HexWrapper)`
  left: unset;
  right: -55px;
  top: 40%;
`;

export const HexWrapperThree = styled(HexWrapper)`
  top: 70%;
`;

export const SlidesContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 125px 0;
`;

export const LottieContainer = styled.div`
  position: sticky;
  top: 140px;
  height: fit-content;
`;

export const MobileSlideWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 32px;
  max-width: 770px;
  margin: auto;
  padding: 48px 24px;
  color: ${colors.base.white};
  text-align: center;

  ${atMinWidth.sm`
    padding: 56px 35px
  `}

  ${atMinWidth.md`
    padding: 72px 24px
  `}

  ${atMinWidth.lg`
    padding: 96px 35px
  `}
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}
`;

export const Subhead = styled.div`
  ${font('text', 'md', '400')}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;
